import * as React from "react"
import Layout from "../components/layout"
import SoftwareDevelopment from "../components/Services/SoftwareDevelopment"






const software = () => {
  return (
  <Layout>
   <SoftwareDevelopment/>
  </Layout>
)
};
export default software;
