import React from "react"
import { Link } from 'gatsby'
import quality from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
import speed from "../../assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp"
import security from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
import softwareimage from "../../assets/images/banners/service-images/software.jpg"

import gathering from "../../assets/images/banners/icon-pack/information.png"
import features from "../../assets/images/banners/icon-pack/features.png"
import strategy from "../../assets/images/banners/icon-pack/roadmap.png"
import design from "../../assets/images/banners/icon-pack/web-design.png"



const SoftwareDevelopment = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img custom-software"
          data-bg="assets/images/banners/service-banners/software.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">
                    Custom Software Development
                  </h1>
                  <h5 className="font-weight--normal text-white">
                    We turn ideas into reality with our custom software
                    development services. Streamline your business operations
                    with web apps and systems that are effective, engaging, and
                    accessible.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Custom <span className="text-color-primary">Software Development</span></h2>
                  Aamanto is a Custom Software Development, Cloud
                  Engineering, Quality Assurance, and DevOps firm
                  specializing in developing time-sensitive and
                  technological ideas. Aamanto provides subject
                  experience, excellent engineering talent, rigorous
                  Agile development methodologies, commitment to client
                  IP protection, and accountability of outstanding
                  reputation to every project.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={softwareimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

           <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper bg-gray section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Process of <span className="text-color-primary">Custom Software Development</span></h2>
                  <p>Aamanto web design and development team has developed a number of useful services to help you transform your business.</p>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Gathering Information</h5>
                            <div className="text">
                              Clients are our top priority, and we endeavor to
                              provide them with only the best service. As a
                              result, we begin by examining the expectations and
                              requirements of our clients. Before moving
                              forward, our team gathers all relevant information
                              about your company, goals, market, rivals, and
                              other factors.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={quality} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Features Declarations</h5>
                            <div className="text">
                              Once we have all the crucial facts that our client
                              is seeking on the website, we will produce a
                              framework detailing those elements, including
                              features, design, and functions. Clients are able
                              to review the pre-defined guidelines and make any
                              adjustments they see appropriate.Our group
                              complies with Google's standards concerning
                              website improvement.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Strategy Formulation</h5>
                            <div className="text">
                              Our team moves forward with the strategy
                              development process after enumerating all the
                              demands and comprehending our clients'
                              perspectives. Our experts will offer the ideal
                              solution for your project based on your
                              objectives, ensuring that you receive only the
                              best web development services.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Design and Coding</h5>
                            <div className="text">
                              Following that, we'll begin the coding and design
                              process while keeping the standards insight. Our
                              in-house designer will take care of all the
                              essential details for a perfect and user-friendly
                              website. Our team adheres to Google's rules in
                              regard to search engine optimization.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={gathering} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Gathering Information</h5>
                            <div className="v1-text-2">
                              Clients are our top priority, and we endeavor to provide them with only the best service. As a result, we begin by examining the expectations and requirements of our clients. Before moving forward, our team gathers all relevant information about your company, goals, market, rivals, and other factors.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={features} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Features Declarations</h5>
                            <div className="v1-text-2">
                              Once we have all the crucial facts that our client is seeking on the website, we will produce a framework detailing those elements, including features, design, and functions. Clients are able to review the pre-defined guidelines and make any adjustments they see appropriate.Our group complies with Google's standards concerning website improvement.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={strategy} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Strategy Formulation</h5>
                            <div className="v1-text-2">
                              Our team moves forward with the strategy development process after enumerating all the demands and comprehending our clients' perspectives. Our experts will offer the ideal solution for your project based on your objectives, ensuring that you receive only the best web development services.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={design} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Design and Coding</h5>
                            <div className="v1-text-2">
                              Following that, we'll begin the coding and design process while keeping the standards insight. Our in-house designer will take care of all the essential details for a perfect and user-friendly website. Our team adheres to Google's rules in regard to search engine optimization.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* <!--===========  feature-large-images-wrapper  End =============-->
            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Why Choose Aamanto For <span className="text-color-primary">Custom Software Development</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Debugging </h5>
                      <div className="desc">
                        We are pleased with our high-quality service and will
                        not compromise it at any cost. We have established a
                        separate team of skilled workers to oversee the tasks we
                        do. The website will be sent to the customer once it has
                        been approved. We also provide our clients with
                        post-delivery support to guarantee that they fully
                        comprehend the functionality.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Fast and Reliable Service </h5>
                      <div className="desc">
                        Our organization is dedicated to its work and to meeting deadlines so that you can receive your website on time.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Result-Oriented Methods </h5>
                      <div className="desc">
                        We create a set of criteria to focus on your prestigious
                        project and give you exactly what you've paid for in
                        order to take over the market.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Dedicated Team Members </h5>
                      <div className="desc">
                        Every developer and designer on the team of Aamanto has
                        been hand-picked to deliver better results.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Project Execution </h5>
                      <div className="desc">
                        Websites are prone to bugs and technological faults, as
                        we all know. As a result, each designed website must go
                        through a rigorous quality testing process before being
                        delivered. The client has access to it after it is clear
                        of any problems and issues. We also keep in touch with
                        the client on a regular basis in case he or she has any
                        concerns about the website.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Complete Confidentiality</h5>
                      <div className="desc">
                        Our team will keep you updated on the development and
                        share any necessary information with you at every stage
                        of the procedure
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Prioritize Customer Satisfaction
                      </h5>
                      <div className="desc">
                        Client satisfaction is of the utmost importance to us.
                        As a result, we make every effort to create a website
                        that exceeds your objectives.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}
export default SoftwareDevelopment
